@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');

.modal-add-to-card .modal-dialog .modal-content {
    width: 90%;
}

.modal-add-to-card .modal-dialog.modal-dialog-centered {
    justify-content: center;
}

.custom-btn-hidden {
    opacity: 0;
    visibility: hidden;
}

/* .modal-dialog {
    position: fixed;
    top: auto;
    right: auto;
    left: auto;
    bottom: 0;
} */

.img-filter {
    height: 20px;
    width: 20px;
    flex-shrink: 0;
}

.choose-a-side {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: left;
    color: #4A4A6A;
    margin-top: 16px;
}

.custom-radio-yellow .form-check-input:checked {
    background-color: #FFB01D;
    border-color: #FFB01D;
    height: 20px;
    width: 20px;
}

.custom-radio-yellow .form-check-input {
    height: 20px;
    width: 20px;
}

.text-choose-a-side {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
}

.text-666687 {
    color: #666687;
}

.text-12B064 {
    color: #12B064;
}

.text-more-request {
    width: 100%;
    height: 82px;
    padding: 12px 16px 12px 16px;
    border-radius: 16px;
    border: 1px solid #DCDCE4;
    gap: 10px
}


.text-more-request:focus {
    outline: none;
}

.pb-footer {
    padding-bottom: 150px;
}

.overflow-y-scroll {
    overflow-y: scroll;
}

.pb-250px {
    padding-bottom: 250px;
}

.react-modal-sheet-content {
    overflow-y: scroll;
    scroll-behavior: auto;
}

.custom-choose-categories {
    border-radius: 16px;
    border: 1px solid var(--neutral-neutral-200, #DCDCE4);
    background: var(--neutral-neutral-0, #FFF);
    padding: 12px 14px;
}

.custom-choose-categories-2 {
    border-radius: 16px;
    border: 1px solid var(--neutral-neutral-200, #DCDCE4);
    border-radius: 16px;
    background: var(--secondary-color-yellow-1, #FFB01D);
    padding: 12px 14px;
}

.box-mess-error {
    padding: 6px 10px;
    border-radius: 8px;
    border: 1px solid red;
}