.text-price-Online {
  font-weight: 700;
  color: #4a4a6a;
}

.text-order-more-detail {
  color: white;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
}

.title-food-detail {
  font-size: 16px;
  font-weight: 700;
  font-family: Roboto;

}

.title-price-detail {
  font-size: 12px;
  font-weight: 700;
  font-family: Roboto;
}

.title-note-food {
  color: #12B064;
  font-size: 12px;
  font-weight: 600;
}

.custom-h-my-bill-detail {
  height: calc(100vh - 86px);
  overflow-y: scroll;
}