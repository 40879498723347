.w-40 {
  width: 40%;
}

.btn-save-delivery {
  border-radius: 12px;
  background: #12B064;
  padding: 12px 20px;
  text-align: center;
  font-size: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  width: fit-content !important;
}

.bg-f0f0f0 {
  background: #f0f0f0;
}

.btn-add-new-address {
  border-radius: 12px;
  background: #FF7B2C;
  padding: 12px 20px;
  text-align: center;
  font-size: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  width: fit-content !important;
}