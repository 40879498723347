.h-107px {
    height: 107px;
}



.text-numberic-no {
    /* //styleName: Button/Medium; */
    font-family: Roboto, sans-serif;
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: white;
}

.text-numberic {
    /* //styleName: Heading/Heading 3; */
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
    color: white;
}

.w-100-707px {
    width: calc(100% - 708px);
}

.w-100-80px {
    width: calc(100% - 80px);
}

.food-name-menu {
    /* //styleName: Heading/Heading 3; */
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0px;
    text-align: left;
    color: #4A4A6A;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.quanlity-product-menu {
    font-family: Roboto, sans-serif;
    font-size: 17px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: left;
    color: #A5A5BA;

}

.text-12B064 {
    color: #12B064;
}

.text-function-window-menu {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.001em;
    text-align: left;
}

.active-category {
    font-family: Roboto, sans-serif;
    font-size: 17px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: left;
    color: #666687;
}

.text-ff521c {
    color: #ff521c;
}

.text-4a4a6a {
    color: #4A4A6A;
}

.w100vw-1000px {
    width: calc(100vw - 1000px);
}